<template>
  <div data-app>
    <v-card>
      <v-card-title>
        {{ cardTitle }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="transportOrderCollection"
        :search="search"
        :sort-by="['to_number']"
        sort-desc="true"
        @click:row="handleClickItem"
        :loading="loadingTable"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <TransportOrderModalForm
              :modalData="modalData"
              :permissions="permissions"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
              @new="handleNew"
            ></TransportOrderModalForm>
            <v-spacer></v-spacer>
            <DeleteModalDialog
              :dialogDelete="dialogDelete"
              @closeDelete="handleCloseDelete"
              @deleteItemConfirm="handleDeleteItemConfirm"
            >
            </DeleteModalDialog>
          </v-toolbar>
        </template>
        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header">
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th style="max-width: 150px">
                <v-select
                  v-model="statusFilter"
                  :items="[{ status: 'Select' }, ...statusCollection]"
                  item-text="status"
                  item-value="status"
                ></v-select>
              </th>
              <th></th>
            </tr>
          </thead>
        </template>
        <!-- <template v-slot:item.status="{ item }">
          <span v-if="statuses.system">{{
            statuses.companySettings.department[item.status].value
          }}</span>
        </template> -->
        <template v-slot:item.actions="{ item }">
          <!-- <router-link
            :to="`/inventory/quantityunits/${item.id}`"
            v-if="permissionCan('view')"
          >
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
          </router-link> -->
          <v-icon
            v-if="permissionCan('delete') && item.deletable"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import TransportOrderModalForm, {
  initialFormData,
} from "./TransportOrderModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

export const ENDPOINT = "transport/transportOrder/";
export const PERMISSION_TO = "transport.transportOrder.";

export default {
  name: "TransportOrders",
  components: { TransportOrderModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      routeName: "transportOrders",
      routePath: "/transport/transportOrders/",
      permissionTo: PERMISSION_TO,
      endPoint: ENDPOINT,
      statusFilter: "Select",

      cardTitle: "Transport orders",
      search: "",
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: "Transaction number",
          value: "to_number",
        },
        {
          text: "Scheduled date",
          value: "scheduled_date",
        },
        {
          text: "Owner name",
          value: "owner_name",
        },
        {
          text: "State",
          value: "state",
          filter: (value) => {
            if (this.statusFilter == "Select") return true;
            return value == this.statusFilter;
          },
        },
        // { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
      loadingTable: true,
    };
  },

  computed: {
    ...mapGetters(["transportOrderCollection"]),

    statusCollection() {
      return this.transportOrderCollection.map((item) => {
        return item.state;
      });
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function(id) {
        if (id) {
          this.$nextTick(() => {
            this.editItem(id);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchTransportOrder"]),

    fetchModel() {
      return this.fetchTransportOrder();
    },
  },

  async mounted() {
    this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>
