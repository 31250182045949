<template>
  <v-dialog v-model="modalData.dialog" max-width="1450px" persistent scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        New transport order
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn
              @click="resetFormData"
              style="float: right; cursor: pointer"
              icon
              color="#26223c"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-tabs dark background-color="#232341" fixed-tabs>
              <v-tabs-slider color="#232341"></v-tabs-slider>
              <v-tab
                v-for="(i, index) in statuses"
                :key="index"
                :href="'#tab-' + index"
                :disabled="i != formModel.status"
              >
                <span style="color: #fff">{{
                  $t("TRANSPORT.status_" + i)
                }}</span>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-container>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="3" md="3">
                <v-select v-model="selectedLocale" :items="languages">
                  <template slot="selection" slot-scope="slotProps">
                    <span class="symbol symbol-20 mr-3">
                      <img :src="selectedLocale.flag" alt="" />
                    </span>
                    {{ slotProps.item.name }}
                  </template>
                  <template v-slot:item="slotProps">
                    <span class="symbol symbol-20 mr-3">
                      <img :src="slotProps.item.flag" alt="" />
                    </span>
                    <span class="navi-text">{{ slotProps.item.name }}</span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="pt-3 pb-3">
              <v-col cols="6" sm="6" md="6">
                <v-autocomplete
                  v-model="transaction"
                  :items="transactions"
                  item-text="transaction_number"
                  label="Select transaction"
                  return-object
                  :disabled="formModel.entities.length > 0 || formModel.id"
                  @change="getItemEntityList"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <!-- <v-autocomplete
                  v-if="
                    !formModel.id &&
                    transaction &&
                    transaction.type == 'project'
                  "
                  v-model="POtransaction"
                  :items="computedPOtransactions"
                  item-text="transaction_number"
                  label="Select purchase order"
                  return-object
                  @change="handleChangePOTransaction"
                >
                </v-autocomplete> -->
                <!-- @change="handleChangePOTransaction" -->
                <v-autocomplete
                  v-if="
                    !formModel.id &&
                    transaction &&
                    transaction.type == 'project'
                  "
                  v-model="supplierType"
                  :items="supplierTypeCollection"
                  item-text="name"
                  label="Select supplier type"
                  return-object
                  @change="resetSelects"
                >
                </v-autocomplete>

                <v-autocomplete
                  v-if="supplierType && !formModel.id"
                  v-model="company"
                  :items="supplierType.companies"
                  item-text="name"
                  label="Select company"
                  return-object
                  @change="formModel.destination = {}"
                >
                </v-autocomplete>

                <v-autocomplete
                  v-if="company && !formModel.id"
                  v-model="selectedAddress"
                  :items="computedAddresses"
                  label="Select address"
                  return-object
                  @change="handleChangeAddress"
                >
                  <template slot="selection" slot-scope="slotProps">
                    {{ slotProps.item.country }}-{{ slotProps.item.postcode }}
                    {{ slotProps.item.city }}, {{ slotProps.item.street }}
                  </template>
                  <template v-slot:item="slotProps">
                    {{ slotProps.item.country }}-{{ slotProps.item.postcode }}
                    {{ slotProps.item.city }}, {{ slotProps.item.street }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <label for="datepicker-full-width">Scheduled date</label
                ><b-form-datepicker
                  id="datepicker-expiration_date"
                  v-model="formModel.scheduled_date"
                  :min="new Date(Date.now())"
                  calendar-width="100%"
                  locale="en"
                  start-weekday="1"
                  class="mb-2"
                  style="max-width: 260px"
                  required
                  @context="messages['scheduled_date'] = ''"
                  :disabled="formModel.id"
                ></b-form-datepicker>
                <small v-if="messages['scheduled_date']" style="color: red">
                  {{ messages["scheduled_date"] }}
                </small>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn
                v-if="formModel.id && formModel.status != 10"
                class="button"
                color="primary mr-2 mt-8"
                elevation="2"
                target="_blank"
                @click="handleClose"
                >Close</v-btn
              >

              <v-btn
                v-if="formModel.id"
                class="button"
                color="primary mr-2 mt-8"
                elevation="2"
                target="_blank"
                @click="handlePrint"
                >Print</v-btn
              >
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-select
                  v-model="formModel.sourceSite"
                  v-if="
                    !formModel.id &&
                    transaction &&
                    (transaction.type == 'sales_order' ||
                      transaction.type == 'project' ||
                      transaction.type == 'purchase_order')
                  "
                  :items="siteCollection"
                  label="Source"
                  item-text="name"
                  item-value="id"
                  return-object
                  :rules="requiredRules"
                  :id="dynamicID"
                  @change="handleChangeSource"
                />
                <!-- <pre>
                  {{siteCollection}}
                </pre> -->
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-select
                  v-model="formModel.destinationSite"
                  v-if="!formModel.destination && transaction"
                  :items="siteCollection"
                  label="Destination"
                  item-text="name"
                  item-value="id"
                  return-object
                  :id="dynamicID"
                  @change="handleChangeDestination"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <table>
                  <tr v-if="formModel.source">
                    <th style="min-width: 120px">Source:</th>
                    <td></td>
                  </tr>
                  <tr v-if="formModel.source && formModel.source.company_name">
                    <th>Company:</th>
                    <td>{{ formModel.source.company_name }}</td>
                  </tr>
                  <tr>
                    <th>Shipping address:</th>
                    <td
                      v-if="
                        formModel.source && formModel.source.shipping_address
                      "
                    >
                      {{ formModel.source.shipping_address }}
                    </td>
                  </tr>
                  <tr v-if="formModel.source && formModel.source.tax_number">
                    <th>Tax number:</th>
                    <td>{{ formModel.source.tax_number }}</td>
                  </tr>
                </table>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <table>
                  <tr v-if="formModel.destination">
                    <th style="min-width: 120px">Destination:</th>
                    <td></td>
                  </tr>
                  <tr
                    v-if="
                      formModel.destination &&
                      formModel.destination.company_name
                    "
                  >
                    <th>Company:</th>
                    <td>{{ formModel.destination.company_name }}</td>
                  </tr>
                  <tr>
                    <th>Shipping address:</th>
                    <td
                      v-if="
                        formModel.destination &&
                        formModel.destination.shipping_address
                      "
                    >
                      {{ formModel.destination.shipping_address }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      formModel.destination && formModel.destination.tax_number
                    "
                  >
                    <th>Tax number:</th>
                    <td>{{ formModel.destination.tax_number }}</td>
                  </tr>
                </table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-data-table
                  v-if="!formModel.id"
                  v-model="selectedEntities"
                  :headers="headersEntityList"
                  :items="computedEntityList"
                  item-key="item_entity_id"
                  show-select
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-row class="text-right">
                      <v-spacer></v-spacer>
                      <v-col cols="3" sm="6" md="6">
                        <b
                          >Total weight: {{ computedSelectedEntitiesWeight }}Kg
                        </b>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-row justify="space-around">
              <v-col cols="2" sm="2" md="2">
                <v-btn
                  v-if="!formModel.id"
                  color="primary mr-2"
                  elevation="2"
                  :disabled="selectedEntities.length == 0"
                  @click="handleAddSelectedEntities"
                  >Add to list</v-btn
                >
              </v-col>
            </v-row>

            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-data-table
                  :search="search"
                  v-model="selectedEntities"
                  :headers="headersEntity"
                  :items="formModel.entities"
                  item-key="item_entity_id"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-row class="text-right">
                      <v-spacer></v-spacer>
                      <v-col cols="3" sm="6" md="6">
                        <b>Total weight: {{ formModel.total_weight }}Kg </b>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item.actions="{ item }" v-if="!formModel.id">
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="mt-15">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="resetFormData">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ENDPOINT, PERMISSION_TO } from "./TransportOrders";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import Swal from "sweetalert2";

const TRANSLATED_ATRIBUTES = [];

export const initialFormData = () => ({
  id: null,
  status: 0,
  scheduled_date: null,
  source: null,
  destination: null,
  total_weight: 0,
  sourceSite: null,
  translations: [],
  entities: [],
});

export default {
  name: "TransportOrderModalForm",

  props: ["modalData", "permissions"],

  mixins: [formModelMixins],

  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      permissionTo: PERMISSION_TO,
      endPoint: ENDPOINT,
      formValid: false,
      messages: {},
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length < 15 || "Name must be max 16 characters",
      ],
      requiredRules: [(v) => !!v || "This field is required"],

      transactions: [],
      transaction: null,
      // POtransaction: null,
      supplierType: null,
      company: null,
      statuses: [0, 5, 10],

      search: "",

      entityList: [],
      selectedEntities: [],

      headersEntityList: [
        {
          text: "Entity ID",
          align: "start",
          sortable: false,
          value: "item_entity_id",
        },
        { text: "Reference", value: "reference_number" },
        { text: "Item name", value: "name" },
        { text: "Quantity", value: "quantity" },
        { text: "Quantity unit", value: "quantityUnit" },
        { text: "Serial number", value: "serial_number" },
        { text: "State", value: "state" },
        { text: "Storage", value: "storage" },
        { text: "Weight", value: "weight" },
      ],

      headersEntity: [
        {
          text: "Entity ID",
          align: "start",
          sortable: false,
          value: "item_entity_id",
        },
        { text: "Reference", value: "reference_number" },
        { text: "Item name", value: "name" },
        { text: "Quantity", value: "quantity" },
        { text: "Quantity unit", value: "quantityUnit" },
        { text: "Serial number", value: "serial_number" },
        { text: "State", value: "state" },
        { text: "Storage", value: "storage" },
        { text: "Delivery date", value: "delivery_date" },
        { text: "Weight", value: "weight" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  computed: {
    ...mapGetters(["siteCollection", "supplierTypeCollection"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? "Transport order - " + this.formModel.to_number
        : "New transport order";
    },

    computedAddresses() {
      console.log(this.company);
      if (!this.company) {
        return [];
      }

      let addresses = [
        ...this.company.addresses.billing,
        ...this.company.addresses.shipping,
      ];
      if (
        this.company.addresses.hq.city ||
        this.company.addresses.hq.country ||
        this.company.addresses.hq.postcode ||
        this.company.addresses.hq.street
      ) {
        addresses.push(this.company.addresses.hq);
      }
      return addresses;
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },

    computedEntityList() {
      let entityIds = this.formModel.entities.map((i) => i.item_entity_id);

      return this.entityList
        .filter((item) => {
          return !entityIds.includes(item.item_entity_id);
        })
        .map((item) => this.formatItem(item));
    },

    computedSelectedEntitiesWeight() {
      if (this.selectedEntities.length == 0) return 0;

      return this.selectedEntities.reduce(function (acc, obj) {
        return acc + obj.weight;
      }, 0);
    },

    editable() {
      return this.formModel.id;
    },

    // computedPOtransactions() {
    //   if (!this.transactions) return [];

    //   return this.transactions.filter((tr) => tr.type == "purchase_order");
    // },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.fetchTransactions();
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getActiveLanguage();
        });
        if (value.editedId) {
          this.loader = true;
          ApiService.get(ENDPOINT + value.editedId)
            .then(({ data }) => {
              data.entities.map((item) => this.formatItem(item));

              this.formModel = Object.assign({}, data);

              this.transaction = this.transactions.find((item) => {
                return (
                  item.transaction_number == this.formModel.transaction_number
                );
              });
              this.setTranslatedAttributes();
              // this.POtransaction = null;
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.transaction = null;
          // this.POtransaction = null;
          this.setTranslatedAttributes();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    ...mapActions(["fetchSite", "fetchSupplierType"]),

    formatItem(item) {
      item.weight = item.quantity * item.unit_weight;
      item.name = this.$helpers.getTranslated(item.translations).name;
      item.quantityUnit = this.$helpers.getTranslated(
        item.quantity_unit_translations
      ).name;
      item.state = this.$t("ADJUSTMENT.status_" + item.status);
      return item;
    },

    resetFormData() {
      this.formModel = Object.assign({}, initialFormData());
      this.transaction = null;
      this.supplierType = null;
      this.company = null;
      // this.POtransaction = null;
      this.transactions = [];
      this.entityList = [];
      this.selectedEntities = [];
      this.handleCloseModalForm();
    },

    calculateEntitiesTotalHeight() {
      if (this.formModel.entities.length == 0) return 0;

      this.formModel.total_weight = this.formModel.entities.reduce(function (
        acc,
        obj
      ) {
        return acc + obj.weight;
      },
      0);
    },

    handleAddSelectedEntities() {
      this.formModel.entities = [
        ...new Set([...this.formModel.entities, ...this.selectedEntities]),
      ];
      this.selectedEntities = [];
      this.calculateEntitiesTotalHeight();
    },

    handleChangeSource(item) {
      this.formModel.source = {};

      let contactData = item.contact_data[0];
      this.formModel.source.shipping_address =
        item.country_id +
        "-" +
        contactData.postcode +
        ", " +
        contactData.city +
        ", " +
        contactData.street;
    },

    handleChangeDestination(item) {
      this.formModel.destination = {};

      let contactData = item.contact_data[0];
      this.formModel.destination.shipping_address =
        item.country_id +
        "-" +
        contactData.postcode +
        ", " +
        contactData.city +
        " " +
        contactData.street;
    },

    destinationAddressAlert() {
      Swal.fire({
        title: "",
        text: "There is no shipping address on the selected transaction!",
        icon: "error",
      });
    },

    handleSaveModalForm() {
      if (
        !this.formModel.destination ||
        !this.formModel.destination.shipping_address
      ) {
        this.destinationAddressAlert();
      } else {
        let model = Object.assign({}, this.formModel);

        this.$refs.form.validate();

        if (this.formValid) {
          this.resetErrorMessages();
          // this.modalData.loading = true;

          /**Delete unused translations */
          if (TRANSLATED_ATRIBUTES.length > 0) {
            let translations = {};
            i18nService.languages.forEach((item) => {
              let langCode = item.lang;
              if (
                model.translations[langCode] &&
                model.translations[langCode].name != ""
              ) {
                translations[langCode] = Object.assign(
                  {},
                  model.translations[langCode]
                );
              }
            });
            if (Object.keys(translations).length != 0) {
              model.translations = translations;
            }
          }
          /**Delete unused translations */
          this.loader = true;
          if (model.id) {
            ApiService.put(ENDPOINT + model.id, model)
              .then(() => {
                this.$emit("saveModalForm");
              })
              .catch((error) => {
                if (error.response) {
                  let errors = error.response.data;
                  if (errors) {
                    for (let field in errors) {
                      this.setError(field, errors[field][0]);
                    }
                  }
                }
              })
              .finally(() => {
                this.loader = false;
              });
          } else {
            //create model
            ApiService.post(ENDPOINT, model)
              .then(() => {
                this.$emit("saveModalForm");
              })
              .catch((error) => {
                if (error.response) {
                  let errors = error.response.data;
                  if (errors) {
                    for (let field in errors) {
                      this.setError(field, errors[field][0]);
                    }
                  }
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log("Error", error.message);
                }
                console.log("Error!: ", error);
                // this.modalData.loading = false;
              })
              .finally(() => {
                this.loader = false;
              });
          }
        }
      }
    },

    getItemEntityList(transaction) {
      if (transaction.destination) {
        this.formModel.destination = transaction.destination;
      }

      if (
        (!this.formModel.destination ||
          !this.formModel.destination.shipping_address) &&
        transaction.type != "project"
      ) {
        this.destinationAddressAlert();
      }

      if (transaction.source) {
        this.formModel.source = transaction.source;
      }

      let sendData = {
        s: transaction.transaction_number,
        statuses: [0, 1, 2, 3, 4, 9],
      };

      ApiService.post("transport/transportOrder/itemEntity/search", sendData)
        .then(({ data }) => {
          this.entityList = data;
          this.formModel.transaction_number =
            this.transaction.transaction_number;

          if (data.destination) {
            this.formModel.destination = {};
            this.formModel.destination.company_name =
              data.destination.company_name;
            this.formModel.destination.tax_number = data.destination.tax_number;
            this.formModel.destination.shipping_address =
              data.destination.shipping_address;
          }
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    // handleChangePOTransaction(data) {
    //   // console.log(data);
    //   if (data.destination) {
    //     this.formModel.destination = {};
    //     this.formModel.destination.company_name = data.destination.company_name;
    //     this.formModel.destination.tax_number = data.destination.tax_number;
    //     this.formModel.destination.shipping_address =
    //       data.destination.shipping_address;
    //   }
    // },

    handleChangeAddress(data) {
      this.formModel.destination = {};
      this.formModel.destination.company_name = this.company.name;
      this.formModel.destination.tax_number = "";
      this.formModel.destination.shipping_address =
        data.country +
        "-" +
        data.postcode +
        ", " +
        data.city +
        " " +
        data.street;
    },

    resetSelects() {
      this.company = null;
      this.formModel.destination = {};
    },

    fetchTransactions() {
      ApiService.get("transport/transportOrder/transactions")
        .then(({ data }) => {
          this.transactions = data;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    handlePrint() {
      window.open(
        process.env.VUE_APP_PORTAL_URL +
          "print/to/?to=" +
          this.formModel.hash +
          "&locale=" +
          this.selectedLocale.lang +
          "&print=1"
      );
    },

    deleteItem(item) {
      this.formModel.entities = this.formModel.entities.filter(
        (i) => i.item_entity_id != item.item_entity_id
      );
      this.calculateEntitiesTotalHeight();
    },

    handleClose() {
      this.formModel.status = 10;
      this.handleSaveModalForm();
    },
  },

  mounted() {
    this.setTranslatedAttributes();
    this.fetchSupplierType();
    this.fetchSite();
  },
};
</script>

<style scoped>
ul {
  list-style: none;
}
.v-text-field__details {
  color: red !important;
}
</style>
